@import 'styles/vars';

.Map {
  position: relative;
  width: 100%;
  height: 100%;

  > .actions {
    position: absolute;
    left: $margin / 2;
    top: $margin / 2;
    z-index: 1;

    > button {
      margin-right: $margin / 2;
    }
  }

  > .count {
    position: absolute;
    bottom: 5px;
    left: 80px;
    color: #FFF;
    font-weight: 500;
    font-size: 13px;
    background-color: #000;
    padding: 2px;
    border-radius: 4px;
  }

  > .searcher {
    position: absolute;
    top: 60px;
    left: 16px;
    width: 500px;

    input {
      width: 100%;
    }

    .suggestions {
      position: absolute;
      top: 100%;
      left: 0;
      background: #fff;
      color: black;
      border: 1px solid #ccc;
      z-index: 10;
    }
  }
}
